import React from 'react'

type propsType = {
    success : string;
}

const Success = ({success}: propsType) => {
  return (
    <p className='text-center text-danger border p-1 mt-2'>{success}</p>
  )
}

export default Success

import React from 'react'

type propsType = {
  removeTodo : Function;
  removeableTodoId : number;
  setRemoveableTodoId : Function;
}

const DeleteTodo = (props: propsType) => {
  const handelDelete = () => {
    props.removeTodo(props.removeableTodoId);
    props.setRemoveableTodoId(0);
  }
  

  return (
    <div className={`d-flex justify-content-center ${props.removeableTodoId === 0? 'd-none' : ''}`}>
        <button onClick={handelDelete}  className={`btn btn-danger mt-3 `}>Delete</button>
    </div> 
  )
}

export default DeleteTodo

import React  , {useState} from 'react'

type propsType = {
    handleCloseForm : () => void;
    getNewTodo : Function;
    setError : Function;
    setSuccess : Function;
}

const AddTodoForm = ({handleCloseForm, getNewTodo, setError, setSuccess}:  propsType) => {

    const handelOffFrom = () => {
        handleCloseForm();
    }

    const [title, setTitle] = useState('');

      const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {value } = event.target;
        setTitle(value);
      };

      const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if(title.length !== 0){
            getNewTodo(title);
            handelOffFrom();
            setSuccess("Todo Added Succesfully🤞");
        }
        
        else{
            setError("Plese Enter Todo Title!");
        }
      };



  return (
    <form className='card p-2 mt-3' onSubmit={handleSubmit}>
        <div className="d-flex justify-content-between">
            <button type='submit' className='btn btn-success'>Add Todo</button>
            <button type='button' className='rounded-circle border p-1 fw-bold' onClick={handelOffFrom}>X</button>
        </div>
        
        <label htmlFor="title" className="form-label">Title</label>
        <input type="text" className="form-control" id="title" value={title} onChange={handleChange}/>
    </form>
)
}

export default AddTodoForm

import React, {useState} from 'react';
import './App.css';
import DeleteTodo from './components/DeleteTodo';
import HeaderSection from './components/HeaderSection';
import TodoList from './components/TodoList';

const todoList = [
  {
    id : 1,
    title : 'title 1',
    status : true 
  },
  {
    id : 2,
    title : 'title 2',
    status : true 
  },
  {
    id : 3,
    title : 'title 3',
    status : true 
  },
  {
    id : 4,
    title : 'title 4',
    status : true 
  },
  // { 
  //   id : 5,
  //   title : 'title 5',
  //   status : true 
  // },
  // { 
  //   id : 6,
  //   title : 'title 6',
  //   status : true 
  // },
  // { 
  //   id : 7,
  //   title : 'title 7',
  //   status : true 
  // },
  // { 
  //   id : 8,
  //   title : 'title 8',
  //   status : true 
  // },
  // { 
  //   id : 9,
  //   title : 'title 9',
  //   status : true 
  // },
]

function App() {

  const [todo, setTodo] = useState(todoList);
  const [removeableTodoId, setRemoveableTodoId] = useState(0);

  const getNewTodo = (title: string) => {

    const currentDate = new Date();
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
    const seconds = String(currentDate.getSeconds()).padStart(2, '0');
    
    const formattedTime = `${hours}${minutes}${seconds}`;
    const id = Number(formattedTime);

    const newTodo = {
      id: id,
      title: title,
      status: true
    };
  
    setTodo([...todo, newTodo]);
  }



  const getTodoId = (todoId: number) => {
    setRemoveableTodoId(todoId);
  }

  const removeTodo = (removableTodoId: number) => {
    const updatedTodoList = todo.filter(todoItem => todoItem.id !== removableTodoId);
    setTodo(updatedTodoList);
  }

  

  return (
  <div className="container h-100">
    <div className="row h-100">
      <div className="col-sm-12 my-auto">
        <div className="center-div">
          <HeaderSection getNewTodo={getNewTodo}/>
          <TodoList todos={todo}  getTodoId={getTodoId}/>  
          <DeleteTodo removeableTodoId={removeableTodoId} removeTodo={removeTodo} setRemoveableTodoId={setRemoveableTodoId}/>
        </div>
      </div>
    </div>
  </div>
  );
}

export default App;

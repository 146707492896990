import React, {useState} from 'react'

type propsType = {
    todo: {
      id: number;
      title: string;
      status: boolean;
  };
  getTodoId: Function;
}

const Todo = (props: propsType) => {

  const [clicked, setClicked] = useState<boolean>(false);

  const setDeletButton = (id: number) =>  {
    props.getTodoId(id);
  }

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setDeletButton(props.todo.id);
    setClicked(true);
  }

  return ( 
    <div onClick={handleClick} className={`p-2 ${clicked ? 'bg-success' : ''}`} style={{borderLeft: "5px solid green" , backgroundColor: "#65B6DB"}}>
        <p className="text-light">Todo Title: {props.todo.title}</p>
        <p className="text-light">Id : {props.todo.id}</p>
    </div>
  )
}

export default Todo

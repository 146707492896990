import React from 'react'

type propsType =  {
    error : string;
}

const Error = ({error}: propsType) => {
  return (
    <p className='text-center text-danger border p-1 mt-2'>{error}</p>
  )
}

export default Error

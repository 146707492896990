import { useState, useEffect } from 'react'
import React from 'react'
import AddTodoForm from './AddTodoForm'
import Error from './Error'
import Success from './Success'

type propType = {
  getNewTodo : Function;
}

const HeaderSection = (props: propType) => {

const [isFormOpen, setIsFormOpen] = useState<boolean>(false);

const handleOpenForm = () => {
  setIsFormOpen(true);
};

const handleCloseForm = () => {
  setIsFormOpen(false);
};

const [error, setError] = useState<string>('');


useEffect(() => {
  let timeoutId: NodeJS.Timeout;

  if (error.length > 0) {
    timeoutId = setTimeout(() => {
      setError('');
    }, 2000);
  }

  // Cleanup function to clear timeout when component unmounts
  return () => {
    clearTimeout(timeoutId);
  };
}, [error]);

const [success, setSuccess] = useState<string>('');


useEffect(() => {
  let timeoutId: NodeJS.Timeout;

  if (success.length > 0) {
    timeoutId = setTimeout(() => {
      setSuccess('');
    }, 2000);
  }

  // Cleanup function to clear timeout when component unmounts
  return () => {
    clearTimeout(timeoutId);
  };
}, [success]);


  return (
    <>
        <div className="d-flex justify-content-between">
            <h3>TODO <span className='text-danger'>APP</span></h3>
            <button className={`btn btn-info text-light ${isFormOpen ? 'disabled' : ''}`}  onClick={handleOpenForm}>
                Add New Todo
            </button>
        </div>
        { error.length != 0 ? <Error error = {error}/> : ''}
        { success.length != 0 ? <Success success = {success}/> : ''}
        
        {isFormOpen && <AddTodoForm getNewTodo = {props.getNewTodo} handleCloseForm = {handleCloseForm} setError = {setError} setSuccess = {setSuccess}/>}
        
    </>
  )
}

export default HeaderSection

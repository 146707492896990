import React from 'react'
import Todo from './Todo'

type propType = {
    todos: { 
        id: number;
        title: string; 
        status: boolean; 
    }[];
    getTodoId: Function;
}

const TodoList = (props: propType) => {

  return (
    <div className='mt-3'>
        <h3 className='text-center'>Todos</h3>
        <div className='row gy-3 mt-3 mx-0 pb-3 border rounded-2 overflow-scroll'>
          {props.todos.map((todo, index) => {
            return (
              <div key = {todo.id} className="col-md-6">
                <Todo todo = {todo} getTodoId = {props.getTodoId}/>
              </div>)
          })} 
          {props.todos.length === 0 && <p className='text-center text-danger'>No Todos Left!</p>}          
        </div>
    </div>  
  )
}

export default TodoList
